import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { BrowserView } from 'react-device-detect'
import Link from '../components/CustomLink'
import Layout from '../components/Layout'
import TagItem from '../components/TagItem'
import { ArrowBackIcon } from '../img/icons'
import PostInfo from '../components/PostInfo'

export const TagTemplate = ({ postLinks, tag, tagHeader, helmet }) => {
  return (
    <section className="p-6 md:p-4 md:pt-8">
      {helmet || ''}
      <BrowserView>
        <Link to="/blog" className="pt-4 block">
          <ArrowBackIcon />
          <span className="ml-1">Back to blog</span>
        </Link>
      </BrowserView>
      <h3 className="text-lg font-medium pt-4">
        {tagHeader} <TagItem text={tag} />
      </h3>
      <ul className="py-2">{postLinks}</ul>
      <div className="my-5">
        <Link to="/tags/" title="Browse all tags">
          Browse all tags
        </Link>
      </div>
    </section>
  )
}

TagTemplate.propTypes = {
  postLinks: PropTypes.array.isRequired,
  tag: PropTypes.string.isRequired,
  tagHeader: PropTypes.string.isRequired,
  helmet: PropTypes.object,
}
class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map(post => (
      <li key={post.node.fields.slug}>
        <Link to={post.node.fields.slug}>{post.node.frontmatter.title}</Link>
        <PostInfo date={post.node.frontmatter.date} readingTime={post.node.fields.readingTime} />
      </li>
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with`

    return (
      <Layout>
        <TagTemplate
          postLinks={postLinks}
          tag={tag}
          tagHeader={tagHeader}
          helmet={<Helmet title={`${tag} | ${title}`} />}
        />
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, publish: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            publish
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
